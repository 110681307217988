// Our main CSS
import '../css/app.css'

/**
 * Vue is ready for you, if you need it.
 */

import mitt from 'mitt'

import axios from 'axios'
window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

let token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
}



/**
 * 🦄: Now do your magic.
 */

let emitter = mitt()
window.emitter = emitter
import { createApp } from 'vue'
import JoinForm from './components/JoinForm.vue'
const vueForms = createApp({})
if (typeof(document.getElementById('vue-js-forms')) != 'undefined' && document.getElementById('vue-js-forms') != null) {
  vueForms.component('join-form', JoinForm)
  vueForms.mount('#vue-js-forms')
}

import FilterJobs from './components/FilterJobs.vue'
const vueFilterJobs = createApp({})
if (typeof(document.getElementById('js-job-feed')) != 'undefined' && document.getElementById('js-job-feed') != null) {
  vueFilterJobs.component('filter-jobs', FilterJobs)
  vueFilterJobs.mount('#js-job-feed')
}

import ExpandableFooter from './components/ExpandableFooter.vue'
const vueFooter = createApp({})
if (typeof(document.getElementById('js-vue-footer')) != 'undefined' && document.getElementById('js-vue-footer') != null) {
  vueFooter.component('expandable-footer', ExpandableFooter)
  vueFooter.mount('#js-vue-footer')
}

const largeHero = createApp({

  methods: {
    toggleJoinForm() {
      emitter.emit('join-form.open', { a: 'b' })
      return document.body.classList.add('overflow-hidden')
    }
  }

})

if (typeof(document.getElementById('js-large-hero')) != 'undefined' && document.getElementById('js-large-hero') != null) {
  largeHero.mount('#js-large-hero')
}


let vueJobEls = document.querySelectorAll('.js-vue-job')

if (vueJobEls.length) {
    vueJobEls.forEach(el => {
    let app = createApp({
      mounted () {
      },
      methods: {
        toggleJoinForm() {
          emitter.emit('join-form.open', { a: 'b' })
          return document.body.classList.add('overflow-hidden')
        }
      }
    })
    app.mount(el)
  })
}


const menuApp = createApp({
  data: () => ({
    active: false,
    toggledItems: [],
    scrolled: false
  }),
  mounted() {
    document.addEventListener('scroll', () => {
        if (! this.scrolled && document.documentElement.scrollTop > window.screen.height) {
            this.scrolled = true
        }

        if (this.scrolled && document.documentElement.scrollTop === 0) {
            this.scrolled = false
        }
    })
  },
  methods: {
    toggle(){
        this.active = ! this.active
        if (this.active) {
            document.body.classList.add('overflow-hidden')
        }
        else {
            document.body.classList.remove('overflow-hidden')
        }
    },
    toggleJoinForm() {
      emitter.emit('join-form.open')
      return document.body.classList.add('overflow-hidden')
    }
  }

})

menuApp.mount('.js-main-menu')

if (document.getElementsByClassName('marquee-section')) {
  import('./marquee').then((module) => {
    module.default()
  })
}