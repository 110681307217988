<template>

    <div class="forms join-form">
        <transition name="slide-left">



            <div v-if="open" class="right-0 bottom-0 top-0 w-full block bg-red text-white fixed z-101 max-w-[1042px] ">

                <button class="absolute bottom-[16px] lg:bottom-auto lg:left-auto lg:-translate-x-0 left-[50%] translate-x-[-50%] md:top-[24px] z-11 md:right-[24px]" @click="closeForm()">
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="56" height="56" rx="28" fill="#252525"/>
                    <rect x="18.4541" y="35.9248" width="24" height="3" transform="rotate(-45 18.4541 35.9248)" fill="white"/>
                    <rect x="35.4248" y="38.0459" width="24" height="3" transform="rotate(-135 35.4248 38.0459)" fill="white"/>
                    </svg>
                </button>

                <div class="overflow-y-scroll py-10 max-h-[100vh] form-scroll px-5 md:px-8 pb-32 lg:pb-0 lg:px-16 pt-20 lg:pt-24" id="join-form" v-if="! successMessage">
                    <div class="max-w-[610px]">
                        <h2 class="mobile-heading-l lg:heading-l mb-4 uppercase">{{ title }}</h2>
                        <div class="body-mobile lg:body mb-12">
                            <slot></slot>
                        </div>
                    </div>
                    <div class="join-form-section mb-12">
                        <h3 class="mobile-heading-s lg:heading-s uppercase mb-4 lg:mb-6">Personlig info</h3>
                        <div class="flex md:-mx-3 flex-wrap">
                            <div class="w-full md:w-1/2 md:px-3 md:mb-6 mb-4">
                                <input-field
                                label="Förnamn"
                                name="firstName"
                                type="text"
                                required="true"
                                :class="{ 'error' : errors.firstName }"
                                v-model="firstName"
                                @focus="highlightedSection = 'first'"
                                >
                                </input-field>
                            </div>
                            <div class="w-full md:w-1/2 md:px-3 md:mb-6 mb-4">
                                <input-field
                                label="Efternamn"
                                name="lastName"
                                type="text"
                                required="true"
                                :class="{ 'error' : errors.lastName }"
                                v-model="lastName"
                                @focus="highlightedSection = 'first'"
                                >
                                </input-field>
                            </div>
                            <div class="w-full md:w-1/2 md:px-3 md:mb-6 mb-4">
                                <input-field
                                label="E-post"
                                name="email"
                                type="text"
                                required="true"
                                :class="{ 'error' : errors.email }"
                                v-model="email"
                                @focus="highlightedSection = 'first'"
                                >
                                </input-field>
                            </div>

                            <div class="w-full md:w-1/2 md:px-3 md:mb-6 mb-4">
                                <input-field
                                label="Telefon"
                                name="phone"
                                type="text"
                                v-model="phone"
                                @focus="highlightedSection = 'first'"
                                >
                                </input-field>
                            </div>
                        </div>
                    </div>
                    <div class="join-form-section mb-12">
                        <h3 class="mobile-heading-s lg:heading-s uppercase mb-4 lg:mb-6">Personliga länkar</h3>
                        <div class="flex md:-mx-3 flex-wrap">

                            <div class="w-full md:w-1/2 md:px-3 md:mb-6 mb-4">

                                <input-field
                                label="LinkedIn-profil"
                                name="linkedin"
                                type="text"
                                required="true"
                                :class="{ 'error' : errors.linkedin }"
                                v-model="linkedin"
                                @focus="highlightedSection = 'second'"
                                >
                                </input-field>

                            </div>

                            <div class="w-full md:w-1/2 md:px-3 md:mb-6 mb-4">

                                <input-field
                                label="Portfolio eller hemsida"
                                name="portfolio"
                                type="text"
                                v-model="portfolio"
                                @focus="highlightedSection = 'second'"
                                >
                                </input-field>

                            </div>

                        </div>
                    </div>
                    <div class="join-form-section">
                        <h3 class="mobile-heading-s lg:heading-s uppercase mb-4 lg:mb-6">{{dreamsLabel}}</h3>
                        <input-field
                        :label="dreamsPlaceholder"
                        name="dreams"
                        type="textarea"
                        v-model="dreams"
                        @focus="highlightedSection = 'fourth'"

                        >
                        </input-field>
                    </div>
                    <div v-if="tags.length > 0" class="join-form-section mt-12">
                        <h3 class="mobile-heading-s lg:heading-s uppercase mb-4 lg:mb-6">Jag är intresserad av</h3>
                        <div v-if="tags.length > 0" class="flex flex-wrap">
                            <a v-for="tag, idx in tags" :key="idx" href="javascript:;" :class="[{active: isTagSelected(tag.name)}]" @click="selectTag(tag.name)" class="label mr-2 mb-2 uppercase border-label"><span class="z-10 relative">{{ tag.name }}</span></a>
                        </div>
                    </div>
                    <div v-if="showOpenTo" class="join-form-section mt-12">
                        <h3 class="mobile-heading-s lg:heading-s uppercase mb-4 lg:mb-6">Jag är öppen för</h3>
                        <div class="flex flex-wrap">
                            <a href="javascript:;" :class="[{active: isTagSelected('Freelance')}]" @click="selectTag('Freelance')" class="label mr-2 mb-2 uppercase border-label"><span class="z-10 relative">Konsult</span></a>
                            <a href="javascript:;" :class="[{active: isTagSelected('Permanent')}]" @click="selectTag('Permanent')" class="label mr-2 mb-2 uppercase border-label"><span class="z-10 relative">Permanent</span></a>
                        </div>
                    </div>
                    <div class="join-form-section mt-12">
                        <input-field
                        label=""
                        required="true"
                        name="policy"
                        type="checkbox"
                        :class="{ 'error' : errors.policy }"
                        v-model="policy"
                        >
                            <span class="text-white">Jag tillåter Dyno att spara min personliga data enligt deras <a href="/privacy/" class="underline" target="_blank">integritetspolicy</a>.</span>
                        </input-field>
                    </div>
                    <div class="py-4">
                        <button :disabled="apiLoading" :class="{ 'opacity-50 cursor-wait': apiLoading }" @click="checkForm" class="btn btn-secondary w-full block submit-btn uppercase" href="javascript:;"><span class="z-10 relative">Gå med</span></button>
                    </div>
                    <p class="body-small pb-8">Dyno inser vikten av att skydda integriteten för dina personuppgifter. Förtroende och öppenhet ligger till grund för allt vi gör. Tekniska och organisatoriska säkerhetsåtgärder har vidtagits för att garantera säkerheten för dina personuppgifter som registrerats hos Dyno. Du kan när som helst utöva din rätt till tillgång, rättelse eller radering genom att kontakta oss på hello@dyno.se</p>
                </div>

                <div class="overflow-y-scroll js-success-join py-10 max-h-[100vh] form-scroll px-5 md:px-8 lg:px-16 text-darkness" id="join-form-complete" v-if="successMessage">
                    <h2 class="heading-s">Tack!</h2>
                    <div class="body max-w-[610px] mb-12">
                        <span v-html="successText"></span>
                        <div class="mt-8"><a class="btn purple full-rounded btn-rotate large-rotate inline-block medium" href="javascript:;" @click="closeForm()">Stäng!</a></div>
                    </div>
                </div>

            </div>

        </transition>
        <div class="join-overlay fixed inset-0 h-full bg-charcoal opacity-50 z-100" @click="closeForm()" v-if="open"></div>
    </div>

</template>

<script>
import InputField from './InputField.vue';

    export default {

        components: { InputField },

        props: ['tags', 'position', 'jobId', 'title', 'showOpenTo', 'successText', 'dreamsLabel', 'dreamsPlaceholder'],

        created() {
            window.emitter.on('join-form.open', () => this.open = true)
        },

        data: () => ({
            open: false,
            selectedTags: [],
            apiPath: '/wp-json/trib-form-api/post',
            errors: [],
            email: '',
            firstName: '',
            lastName: '',
            highlightedSection: '',
            phone: '',
            linkedin: '',
            portfolio: '',
            dreams: '',
            freelance: '',
            permanent: '',
            policy: '',
            successMessage: '',
            errorMessage: '',
            apiLoading: false
        }),
        methods: {
            closeForm() {
                this.open = false
                return document.body.classList.remove('overflow-hidden')
            },
            isTagSelected: function (filter) {
                return this.selectedTags.includes(filter)
            },
            selectTag: function (filter) {
                console.log(filter);
                this.highlightedSection = 'fifth'
                const index = this.selectedTags.indexOf(filter);
                if (index > -1) { // If the specified filter already exists in the filters array, we should remove it.
                    this.selectedTags.splice(index)
                }
                else { // Otherwise we append it to the filtered list.
                    this.selectedTags.push(filter);
                }
            },
            selectInterestTag: function (filter) {
                this.highlightedSection = 'sixth'
                const index = this.selectedTags.indexOf(filter);
                if (index > -1) { // If the specified filter already exists in the filters array, we should remove it.
                    this.selectedTags.splice(index)
                }
                else { // Otherwise we append it to the filtered list.
                    this.selectedTags.push(filter);
                }
            },
            checkForm(e) {

                this.errors = {}

                if (! this.firstName) {
                    this.errors.firstName = 'error'
                }

                if (! this.lastName) {
                    this.errors.lastName = 'error'
                }

                if (! this.email) {
                    this.errors.email = 'error'
                }

                if (! this.linkedin) {
                    this.errors.linkedin = 'error'
                }

                if (! this.policy) {
                    this.errors.policy = 'error'
                }

                // Om this.errors har nycklar, dvs. ej tomt objekt, så har vi errors och bryter här
                if (Object.keys(this.errors).length) {
                    return false;
                }

                this.postForm(e);

                e.preventDefault();
            },
            postForm(e) {
                e.preventDefault();

                this.apiLoading = true;
                axios.post(this.apiPath, {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    phone: this.phone,
                    linkedin: this.linkedin,
                    portfolio: this.portfolio,
                    dreams: this.dreams,
                    position: this.position,
                    jobId: this.jobId,
                    selectedTags: this.selectedTags,
                    freelance: this.freelance,
                    permanent: this.permanent
                })
                .then( (response) => {
                  console.log(response);
                  this.successMessage = 'Thank you! We will get in touch with you shortly.';
                  this.apiLoading = false
                })
                .catch(() => {this.errorMessage = 'Something went wrong, please try again.', this.apiLoading = false})
            }
        },

    }

</script>
