
<template>
    <div>
        <div class="holder">
            <div class="search mb-6">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#252525" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21.0004 20.9999L16.6504 16.6499" stroke="#252525" stroke-width="3" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
                <input class="w-full" type="text" v-model="searchValue" placeholder="Sök roll, job eller företag..." />
            </div>
            <div class="flex mb-6 lg:mb-10 items-center pb-6 lg:pb-0 border-charcoal border-b-2 lg:border-none">
                <p class="label uppercase mr-12">{{ jobAmount }} jobb</p>
                <div class="flex md:justify-start space-x-6 hidden lg:block" :class="tagBorder">
                    <a class="label uppercase hover:underline" :class="{'underline': ! filteredTags.length }" @click="resetFilter()"  href="javascript:;">
                        Alla jobb
                    </a>
                    <a class="label uppercase hover:underline" :class="{'underline': isFilterSelected('freelance')}" @click="toggleFilter('freelance')"  href="javascript:;">
                        Frilans
                    </a>
                    <a class="label uppercase hover:underline" :class="{'underline': isFilterSelected('permanent')}" @click="toggleFilter('permanent')"  href="javascript:;">
                        Permanent
                    </a>
                </div>
                <div class="ml-auto label uppercase" v-if="filledPositionsLink">
                    <a class="flex items-center arrow-holder right uppercase hover:underline" :href="filledPositionsLink" >
                        <span>{{ filledPositionsText }}</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 12H19" stroke="#252525" stroke-width="3"/>
                        <path d="M12 5L19 12L12 19" stroke="#252525" stroke-width="3"/>
                        </svg>
                    </a>
                </div>
            </div>
            <div class="flex mb-6 justify-between md:justify-start space-x-6 lg:hidden" :class="tagBorder">
                <a class="label uppercase hover:underline" :class="{'underline': ! filteredTags.length }" @click="resetFilter()"  href="javascript:;">
                    Alla jobb
                </a>
                <a class="label uppercase hover:underline" :class="{'underline': isFilterSelected('freelance')}" @click="toggleFilter('freelance')"  href="javascript:;">
                    Frilans
                </a>
                <a class="label uppercase hover:underline" :class="{'underline': isFilterSelected('permanent')}" @click="toggleFilter('permanent')"  href="javascript:;">
                    Permanent
                </a>
            </div>
        </div>
        <div class="has-read-more">
            <div>
                <a class="py-10 block list-hover even:light-list" :class="{'light-list': $idx % 2 == 0 }" :href="visibleItem.permalink" v-for="(visibleItem, idx) in visibleItems" :key="idx">
                    <div class="holder">
                        <div class="lg:flex">
                            <div>
                                <h3 class="mobile-heading-l lg:heading-l uppercase mb-2">{{ visibleItem.title }}</h3>
                                <div class="flex items-center">
                                    <p class="mobile-heading-s lg:heading-s uppercase">{{ visibleItem.sub_title }}</p>
                                </div>
                            </div>
                            <div class="mt-4 lg:mt-0 flex items-center ml-auto">
                                <span class="border-label filled uppercase" v-if="visibleItem.position_type">{{ visibleItem.position_type }}</span>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div ref="readMore" class="border-label filled read-more uppercase">Läs mer</div>
        </div>

        <div v-if="visibleItems.length == 0">

        </div>
    </div>
</template>

<script>
export default {
    props: ['items', 'text', 'tagBorder', 'filledPositionsText', 'filledPositionsLink'],

    data: () => ({
        searchValue: '',
        filteredTags: []
    }),
    mounted () {
        let readMore = this.$refs.readMore
        if (typeof(readMore) != 'undefined' && readMore != null) {
            const onMouseMove = (e) =>{
                readMore.style.left = e.pageX + 'px'
                readMore.style.top = e.pageY + 'px'
            }
            document.addEventListener('mousemove', onMouseMove)
        }
        if (window.location.hash) {
            let hash = window.location.hash.replace(/,/g, ' ');
            hash = hash.replace(/#/g, '');
            this.searchValue = hash;
            console.log(this.searchValue);
        }
    },
    methods: {
        isFilterSelected: function (filter) {
            return this.filteredTags.includes(filter)
        },
        toggleFilter: function (filter) {
            this.filteredTags = []
            const index = this.filteredTags.indexOf(filter);
            if (index > -1) { // If the specified filter already exists in the filters array, we should remove it.
                this.filteredTags.splice(index)
            }
            else { // Otherwise we append it to the filtered list.
                this.filteredTags.push(filter);
            }
        },
        resetFilter: function () {
            this.filteredTags = []
        }
    },
    computed: {
        visibleItems: function () {
            return this.items.filter(item => {
                return this.filteredTags.length < 1 || this.filteredTags.includes(item.position_type)
            }).filter(item => {
                return item.title.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1 || item.sub_title.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1 || item.position_type.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1
            }).sort((a, b) => {
                return a.title.toLowerCase().indexOf(this.searchValue.toLowerCase()) > b.title.toLowerCase().indexOf(this.searchValue.toLowerCase()) ? 1 : -1
            })
        },
        jobAmount: function () {
            return this.visibleItems.length
        }
    }
}
</script>